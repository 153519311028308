let landingApp = ((landingApp) => {
    landingApp.init = () => {
        $('#js-btn-showmore').on('click', () => {
            if ($('.text').hasClass('h-350')) {
                $('.text').addClass('h-400').removeClass('h-350');
                $('#js-btn-showmore').html('Show Less...');
            } else {
                $('.text').addClass('h-350').removeClass('h-400');
                $('#js-btn-showmore').html('Show More...');
            }
        });

        $('.js-go-to').hide();


        $('#google_translate_element .goog-te-combo').addClass('form-select');
         
        $(window).scroll(() => {
            if ($(this).scrollTop() > 300) {
                $('.js-go-to').fadeIn();
            } else {
                $('.js-go-to').fadeOut();
            }
        });
        $(".js-go-to a").click((e) => {
            e.preventDefault();
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
        });

        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
            }, i[r].l = 1 * new Date(); a = s.createElement(o),
                m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
        })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

        ga('create', 'UA-39649464-1', 'lakelandhousing.org');
        ga('send', 'pageview');

        let slider = new MasterSlider();

        slider.control('arrows', {
            autohide: true,
            overVideo: true
        });
        slider.control('bullets', {
            autohide: true,
            overVideo: true,
            dir: 'h',
            align: 'bottom',
            space: 6,
            margin: 15
        });
        slider.setup("masterslider", {
            width: 1366,
            height: 768,
            minHeight: 0,
            space: 0,
            start: 1,
            grabCursor: true,
            swipe: true,
            mouse: true,
            keyboard: false,
            layout: "fullwidth",
            wheel: false,
            autoplay: true,
            instantStartLayers: true,
            loop: true,
            shuffle: false,
            preload: 0,
            heightLimit: true,
            autoHeight: false,
            smoothHeight: true,
            endPause: false,
            overPause: true,
            fillMode: "fill",
            centerControls: false,
            startOnAppear: false,
            layersMode: "center",
            autofillTarget: "",
            hideLayers: false,
            fullscreenMargin: 0,
            speed: 20,
            dir: "h",
            parallaxMode: 'swipe',
            view: "stack"
        });
    };

    return landingApp;
})({});