let helperApp = ((helperApp) => {
    helperApp.BlockUI = (element) => {
        $(`div#${element}`).block({
            message: '<h3><i aria-hidden="true" class="bi bi-info-circle mr-1"></i><br />Processing Request... please wait...</h3>'
        });
    };

    helperApp.UnBlockUI = (element) => {
        $(`div#${element}`).unblock();
    };

    helperApp.RefreshUI = () => {
        setTimeout(() => { window.location.reload(true); }, 1000);
    };

    helperApp.HasExtension = (e, exts) => {
        let fileName = e.val();

        return (new RegExp(`(${exts.join('|').replace(/\./g, '\\.')})$`)).test(fileName);
    };

    helperApp.HtmlCleaner = (str) => {
        return str.replace(/[&\/\\#@,+()$~%.'":*?<>{}=]/g, '');
    };

    helperApp.CheckAllowedFiles = (e) => {
        let elem = $(`#${e}`);
        console.log('init');
        elem.bind('change', () => {
            let checkForExt = Boolean(helperApp.HasExtension(elem, ['.pdf', '.PDF', '.jpg', '.jpeg', '.JPG', '.JPEG', '.bmp', '.tiff', '.TIFF', '.png', '.PNG', '.BMP', '.doc', '.DOC', '.docx', '.DOCX']));
            const size = elem[0].files[0].size;
            const allowedSize = 17000000;
            console.log(size);
            if (size >= allowedSize || checkForExt === false) {
                swal({
                    title: 'ERROR!',
                    text: 'File is Over the Size Limit',
                    icon: 'error',
                });

                elem.val('');
            }
        });
    };

    helperApp.DynamicModal = (...args) => {
        let options = {
            'size': args[0],
            'action': args[1],
            'header': args[2],
            'content': args[3]
        }

        $('#js-dynamic-modal').modal(options.action);
        $('#js-dynamic-modal-dialog').addClass(`modal-${options.size}`);
        $('#js-dynamic-modal-label').html(options.header);
        $('#js-dynamic-modal-content').html(options.content);
    };

    helperApp.GlobalCleaner = () => {
        $('[data-toggle="tooltip"]').tooltip();
        $('*[data-purpose="number"').number(true, 0);
        $('*[data-purpose="phone"').mask('(000) 000-0000');

        $('*[data-purpose="currency"').number(true, 2);

        jQuery.validator.setDefaults({ ignore: ":hidden:not(#summernote),.note-editable.panel-body" });
        jQuery.validator.setDefaults({
            ignore: ":hidden, [contenteditable='true']:not([name])"
        });

        $('input[type=text], textarea').keypress((e) => {
            let regex = new RegExp("^[A-Za-z0-9- *#/.'\]+$");
            let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);

            if (regex.test(str)) {
                return true;
            }

            e.preventDefault();
            return false;
        });

        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })
    };

    helperApp.RefreshDOM = () => {
        $('form').removeData('validator');
        $('form').removeData('unobtrusiveValidation');
        $.validator.unobtrusive.parse('form');

        $('form').each(function () {
            if ($(this).data('validator'))
                $(this).data('validator').settings.ignore = ".note-editor *";
        });

        helperApp.GlobalCleaner();
    };

    helperApp.EnableSorter = (e, url) => {
        $('#' + e).sortable({
            update: function (event, ui) {
                let order = $(this).sortable('toArray').toString();
                $.post(url, { order: order });
            },
            connectWith: ".connectList"
        }).disableSelection();
    };

    helperApp.ApplyValidationStyles = (caller) => {
        let form = $(`#${caller}`)
            , formData = $.data(form[0])
            , settings = formData.validator.settings
            , oldErrorPlacement = settings.errorPlacement
            , oldSuccess = settings.success;

        settings.errorPlacement = (label, element) => {
            oldErrorPlacement(label, element);
            label.parents('.form-group').addClass('has-error');
            label.addClass('text-danger');
        };

        settings.success = (label) => {
            label.parents('.form-group').removeClass('has-error');
            oldSuccess(label);
        };
    };

    helperApp.SmartTextArea = (e, chars, warning) => {
        $(`#${e}`).characterCounter({
            maxChars: chars,
            postCountMessage: "chars left",
            postCountMessageSingular: "char left",
            zeroRemainingMessage: "No chars left",
            overrunPreCountMessage: "Please remove",
            overrunPostCountMessage: "chars",
            overrunPostCountMessageSingular: "char",
            positiveOverruns: true
        });
    };

    helperApp.Guid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = Math.random() * 16 | 0, v = c === 'x' ? r : r & 0x3 | 0x8;
            return v.toString(16);
        });
    };

    helperApp.NotySuccess = (message) => {
        new Noty({
            type: "success",
            layout: "topRight",
            timeout: "1500",
            animation: {
                "open": "animated fadeIn",
                "close": "animated fadeOut"
            },
            text: `<i class=\"bi bi-check2-circle me-1\"></i>${message}`,
            theme: 'nest'
        }).show();
    };

    helperApp.NotyValidation = (message) => {
        new Noty({
            type: "warning",
            layout: "topRight",
            timeout: "1500",
            animation: {
                "open": "animated fadeIn",
                "close": "animated fadeOut"
            },
            "text": `<i class=\"bi bi-shield-check me-1\"></i>${message}`,
            "theme": "nest"
        }).show();
    };

    helperApp.NotyError = (message) => {
        new Noty({
            type: "error",
            layout: 'topRight',
            timeout: "1500",
            animation: {
                "open": "animated fadeIn",
                "close": "animated fadeOut"
            },
            text: `<i class=\"bi bi-exclamation-circle me-1\"></i>${message}`,
            theme: "nest"
        }).show();
    };

    helperApp.FormatDate = (dateObj, format) => {
        let monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        let currentDate = dateObj.getDate();
        let currentMonth = dateObj.getMonth();

        currentMonth = currentMonth + 1;

        let currentYear = dateObj.getFullYear();
        let currentMin = dateObj.getMinutes();
        let currentHour = dateObj.getHours();
        let currentSeconds = dateObj.getSeconds();

        if (currentMonth.toString().length === 1)
            currentMonth = '0' + currentMonth;

        if (currentDate.toString().length === 1)
            currentDate = '0' + currentDate;

        if (currentHour.toString().length === 1)
            currentHour = '0' + currentHour;

        if (currentMin.toString().length === 1)
            currentMin = '0' + currentMin;

        switch (format) {
            case 1: // dd-mm-yyyy
                return currentDate + '-' + currentMonth + '-' + currentYear;

            case 2: // yyyy-mm-dd
                return currentYear + '-' + currentMonth + '-' + currentDate;

            case 3: // dd/mm/yyyy
                return currentDate + '/' + currentMonth + '/' + currentYear;

            case 4: // MM/dd/yyyy HH:mm:ss
                return currentMonth + '/' + currentDate + '/' + currentYear + ' ' + currentHour + ':' + currentMin + ':' + currentSeconds;

            case 5: // MM/dd/yyyy
                return currentMonth + '/' + currentDate + '/' + currentYear;

            default:
                return currentMonth + '/' + currentDate + '/' + currentYear;
        }
    };

    helperApp.RefreshDt = (tbl) => {
        let buttons = [];
        let tablix = $(`#${tbl}`).dataTable();

        $.each(tablix.buttons()[0].inst.s.buttons,
            () => {
                buttons.push(this);
            });

        $.each(buttons,
            () => {
                tablix.buttons()[0].inst.remove(this.node);
            });
        tablix.rows().remove().draw();
        tablix.destroy();
    };

    helperApp.InitDtSimple = (tbl, sortby = 0, sortDir = 'desc') => {
        let tablix = $(`#${tbl}`).length > 0 ? $(`#${tbl}`) : $(`.${tbl}`);
        tablix.DataTable({
            pageLength: 10,
            responsive: true,
            dom: '<"html5buttons"B>lTfgitp',
            "order": [[sortby, sortDir]],
            buttons: [
                { extend: 'excel', title: 'grid-report' },
                {
                    extend: 'print',
                    customize: function (win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');
                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }

            ]
        });
    };

    helperApp.InitDt = (tbl, sortby, sortDir = 'desc', search = true, state = false) => {
        let tablix = $(`#${tbl}`).DataTable({
            dom: "Bfrtip",
            "order": [[sortby, sortDir]],
            "sAjaxDataProp": "",
            "deferRender": true,
            stateSave: state,
            lengthMenu: [
                [10, 25, 50, -1],
                ['10 rows', '25 rows', '50 rows', 'Show all']
            ],
            buttons: [
                'excel', 'print', 'pageLength'
            ],
            responsive: true
        });

        $('.dt-buttons').addClass('btn-group-sm d-none d-md-block float-left');
        $('.dataTables_paginate').addClass('d-none d-md-block');
        $('#dtSearch').attr('placeholder', 'search...');
        $('#dtSearch').addClass('d-none d-md-block float-right');

        if ($(window).width() < 500) {
            $('.table').removeClass('table-bordered');
        }

        if (search === false) {
            $('#dtSearch').addClass('d-none');
        }
    };

    helperApp.SelectRow = () => {
        console.log('Selected');
        $('tr').delegate('a', 'click', () => {
            $(this)
                .closest('tr').addClass('bg-danger')
                .siblings('tr').removeClass('bg-danger')
                ;
        });
    };

    helperApp.InitSummernote = (e, mode, height) => {
        let options = {};

        switch (mode) {
            case "simple":
                options = {
                    height: height,
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline', 'clear']],
                        ['insert', ['link', 'picture']],
                    ],
                    cleaner: {
                        notTime: 2400,
                        action: 'both',
                        newline: '<br>',
                        notStyle: 'position:absolute;top:0;left:0;right:0',
                        icon: '<i class="note-icon">[Your Button]</i>',
                        keepHtml: false,
                        keepClasses: false,
                        badTags: ['style', 'script', 'applet', 'embed', 'noframes', 'noscript', 'html'],
                        badAttributes: ['style', 'start']
                    }
                };
                break;

            case "full":
                options = {
                    height: height,
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline', 'clear']],
                        ['font', ['strikethrough', 'superscript', 'subscript']],
                        ['fontsize', ['fontsize']],
                        ['color', ['color']],
                        ['para', ['ul', 'ol', 'paragraph']],
                        ['height', ['height']],
                        ['insert', ['link', 'picture']],
                        ['misc', ['codeview']]
                    ],
                    cleaner: {
                        notTime: 2400,
                        action: 'both',
                        newline: '<br>',
                        notStyle: 'position:absolute;top:0;left:0;right:0',
                        icon: '<i class="note-icon">[Your Button]</i>',
                        keepHtml: false,
                        keepClasses: false,
                        badTags: ['style', 'script', 'applet', 'embed', 'noframes', 'noscript', 'html'],
                        badAttributes: ['style', 'start']
                    }
                };

                break;
            default:
        }

        $(`#${e}`).summernote(options);
    };

    helperApp.FormMode = (action, container) => {
        switch (action) {
            case "new":
                $(`#${container}`).find('[data-action=save]').show();
                $(`#${container}`).find('[data-action=update]').hide();
                $(`#${container}`).find('[data-action=cancel]').hide();
                break;

            case "edit":
                $(`#${container}`).find('[data-action=save]').hide();
                $(`#${container}`).find('[data-action=update]').show();
                $(`#${container}`).find('[data-action=cancel]').show();
                break;

            case "hidden":
            default:
                $(`#${container}`).find('[data-action=save]').hide();
                $(`#${container}`).find('[data-action=update]').hide();
                $(`#${container}`).find('[data-action=cancel]').hide();
                break;
        }
    };

    return helperApp;
})({});