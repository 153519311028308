let portalApp = ((portalApp) => {
    const urls = {
        'loadJobOpeningForm': '/services/initjobopeningmodal/',
        'getResidentDemographics': '/services/residentdemographics/',
        'loadSection8ContactForm': '/housing/section8contact/',
        'removeSubscription': '/portal/mailinglist/deleteSubscriptions/',
    }

    portalApp.initNewsCenter = () => {
        $('#dt-news').dataTable({
            stateSave: true,
            "pageLength": 10,
            "order": [[1, 'desc']],
            lengthMenu: [
                [10, 25, 50, -1],
                ['10 rows', '25 rows', '50 rows', 'Show all']
            ]
        });

        $('#dt-news_wrapper').removeClass('container-fluid');
    };

    portalApp.initBoardPackages = () => {
        $('#dt-packages').dataTable({
            stateSave: true,
            "pageLength": 10,
            "order": [[1, 'desc']],
            lengthMenu: [
                [10, 25, 50, -1],
                ['10 rows', '25 rows', '50 rows', 'Show all']
            ],
            "aoColumnDefs": [
                {
                    "aTargets": [1], //column index counting from the left
                    "sType": 'date',
                }
            ],
        });

        $('#dt-packages_wrapper').removeClass('container-fluid');
        $('#dt-packages').addClass('w-100');
    };

    portalApp.initMinutes = () => {
        $('#dt-minutes').dataTable({
            stateSave: true,
            "pageLength": 10,
            lengthMenu: [
                [10, 25, 50, -1],
                ['10 rows', '25 rows', '50 rows', 'Show all']
            ]
        });

        $('#dt-minutes_wrapper').removeClass('container-fluid');
        $('#dt-minutes').addClass('w-100');
    };

    /**** Forward Job Opening ***/

    portalApp.openFowardJobModal = (domainKey) => {
        axios.get(`${urls.loadJobOpeningForm}${domainKey}`).then((response) => {
            helperApp.DynamicModal('lg', 'show', 'Forward Current Job Opening', response.data);
            $('#CompleteName').focus();
            $('#js-btn-submit').html('Complete the Captcha Validation').attr('disabled', 'disabled');
            helperApp.SmartTextArea('Note', 512, 500);
            helperApp.RefreshDOM();
        })
    };

    portalApp.forwardJob = () => {
        $(`#js-form-forwardjob`).submit((e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            if ($(`#js-form-forwardjob`).valid()) {
                let ajaxOptions = {
                    success: (data) => {
                        helperApp.NotySuccess(data);
                        helperApp.DynamicModal('lg', 'hide', '', '');
                    },
                    error: (xhr, textStatus, errorThrown) => {
                        helperApp.NotyError("An error was logged, if problem persist contact Tech Support");
                    }
                };
                $(`#js-form-forwardjob`).ajaxSubmit(ajaxOptions);
            } else {
                helperApp.NotyError("Some fields in your form are required, complete and then submit...");
            }
        });
    };
    /************************/
    /** */
    portalApp.initContactForm = () => {
        helperApp.SmartTextArea('Comments', 1024, 1000);
        $('#js-btn-submit').html('Complete the Captcha Validation').attr('disabled', 'disabled');
    };
    /** */
    portalApp.initFssForm = () => {
        helperApp.SmartTextArea('Description', 512, 510);
        $('#js-btn-submit').html('Complete the Captcha Validation').attr('disabled', 'disabled');
    };
    /** */
    portalApp.validateCaptcha = () => {
        $('#js-btn-submit').html('Send us your Request').removeAttr('disabled');
        helperApp.RefreshDOM();
    };
    /** */
    portalApp.contactFormsubmission = () => {
        $(`#js-form-agencycontact`).submit((e) => {
            e.preventDefault();
            e.stopImmediatePropagation();

            if ($(`#js-form-agencycontact`).valid()) {
                let ajaxOptions = {
                    success: (data) => {
                        helperApp.NotySuccess(data); $(`#js-form-agencycontact`).trigger('reset');
                        grecaptcha.reset();
                    },
                    error: (xhr, textStatus, errorThrown) => {
                        helperApp.NotyError("An error was logged, if problem persist contact Tech Support");
                    }
                };
                $(`#js-form-agencycontact`).ajaxSubmit(ajaxOptions);
            } else {
                helperApp.NotyError("Some fields in your form are required, complete and then submit...");
            }
        });
    };
    /** */
    portalApp.fssLetterSubmission = () => {
        $(`#js-form-fssletter`).submit((e) => {
            e.preventDefault();
            e.stopImmediatePropagation();

            if ($(`#js-form-fssletter`).valid()) {
                let ajaxOptions = {
                    success: (data) => {
                        helperApp.NotySuccess(data); $(`#js-form-fssletter`).trigger('reset');
                        grecaptcha.reset();
                    },
                    error: (xhr, textStatus, errorThrown) => {
                        helperApp.NotyError("An error was logged, if problem persist contact Tech Support");
                    }
                };
                $(`#js-form-fssletter`).ajaxSubmit(ajaxOptions);
            } else {
                helperApp.NotyError("Some fields in your form are required, complete and then submit...");
            }
        });
    };
    /** */
    portalApp.initAgencyLocations = () => {
        portalApp.initChartsRD();

        let portfolioWork = $('.portfolio-content');
        if (portfolioWork.length > 0) {
            $(portfolioWork).isotope({
                resizable: false,
                itemSelector: '.grid-item',
                layoutMode: 'masonry',
                filter: '*'
            });
            let portfolioFilter = $('.filter li');
            $(portfolioFilter).on('click', () => {
                let filterValue = $(this).attr('data-filter');
                portfolioWork.isotope({
                    filter: filterValue
                });
            });

            $(portfolioFilter).on('click', () => {
                $(this).addClass('active').siblings().removeClass('active');
            });
        }
    }
    /** */
    portalApp.initChartsRD = () => {
        axios.get(urls.getResidentDemographics)
            .then((response) => {
                let labels = [], chartdata1 = [];

                _.forEach(response.data, (obj) => {
                    labels.push(obj.keyIndicator);
                    chartdata1.push(obj.keyValue);
                });

                let barData = {
                    labels: labels,
                    datasets: [{
                        label: "FL011 Resident Demographics (values in %)",
                        backgroundColor: '#0E5480',
                        data: chartdata1
                    }]
                };

                let barOptions = {
                    responsive: true,
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'FL011 Resident Demographics (values in %)'
                    }, scales: {
                        xAxes: [{
                            stacked: true
                        }],
                        yAxes: [{
                            stacked: true
                        }]
                    }
                };

                let ctx2 = $("#js-resident-demographics").get(0).getContext("2d");
                new Chart(ctx2, { type: 'bar', data: barData, options: barOptions });
            });
    };
    /**
     *
     * @param {any} e
     */
    portalApp.onPrivacyOptionChange = (e) => {
        let elem = $(e);

        switch (elem.val()) {
            case 'I wish to remain anonymous':
                $('#ReporterFirstName').val('Anonymous').attr('readonly', 'readonly');
                $('#ReporterLastName').val('Report').attr('readonly', 'readonly');
                $('#ReporterAddress1').val('1 Main St.').attr('readonly', 'readonly');
                $('#ReporterAddress2').val('Apt 123').attr('readonly', 'readonly');
                $('#ReporterCity').val('...').attr('readonly', 'readonly');
                $('#ReporterStateCode').val('...').attr('readonly', 'readonly');
                $('#ReporterZipCode5').val('00000').attr('readonly', 'readonly');
                $('#ReporterEmailAddress').val('noemail@mail.local').attr('readonly', 'readonly');
                $('#ReporterPhoneNumber').val('(555) 555-5555').attr('readonly', 'readonly');
                break;

            case 'I wish to remain anonymous, BUT you can call me for questions/follow up':
                $('#ReporterFirstName').val('Anonymous').attr('readonly', 'readonly');
                $('#ReporterLastName').val('Report').attr('readonly', 'readonly');
                $('#ReporterAddress1').val('1 Main St.').attr('readonly', 'readonly');
                $('#ReporterAddress2').val('Apt 123').attr('readonly', 'readonly');
                $('#ReporterCity').val('...').attr('readonly', 'readonly');
                $('#ReporterStateCode').val('...').attr('readonly', 'readonly');
                $('#ReporterZipCode5').val('00000').attr('readonly', 'readonly');
                $('#ReporterEmailAddress').val('').removeAttr('readonly');
                $('#ReporterPhoneNumber').val('').removeAttr('readonly');
                break;
            default:
                $('#ReporterFirstName').val('').removeAttr('readonly');
                $('#ReporterLastName').val('').removeAttr('readonly');
                $('#ReporterAddress1').val('').removeAttr('readonly');
                $('#ReporterAddress2').val('').removeAttr('readonly');
                $('#ReporterCity').val('').removeAttr('readonly');
                $('#ReporterStateCode').val('').removeAttr('readonly');
                $('#ReporterZipCode5').val('').removeAttr('readonly');
                $('#ReporterEmailAddress').val('').removeAttr('readonly');
                $('#ReporterPhoneNumber').val('').removeAttr('readonly');
                break;
        }
    };
    /** */
    portalApp.submitFraudReport = () => {
        $(`#js-form-fraudreport`).submit((e) => {
            e.preventDefault();
            e.stopImmediatePropagation();

            if ($(`#js-form-fraudreport`).valid()) {
                let ajaxOptions = {
                    success: (data) => {
                        helperApp.NotySuccess(data); $(`#js-form-fraudreport`).trigger('reset');
                        grecaptcha.reset();
                    },
                    error: (xhr, textStatus, errorThrown) => {
                        helperApp.NotyError("An error was logged, if problem persist contact Tech Support");
                    }
                };
                $(`#js-form-fraudreport`).ajaxSubmit(ajaxOptions);
            } else {
                helperApp.NotyError("Some fields in your form are required, complete and then submit...");
            }
        });
    };
    /**
     *
     * @param {any} domainKey
     */
    portalApp.openSection8ContactModal = (domainKey) => {
        axios.get(`${urls.loadSection8ContactForm}${domainKey}`).then((response) => {
            helperApp.DynamicModal('lg', 'show', 'Connect with Section 8 Team Member', response.data);
            $('#js-btn-submit').html('Complete the Captcha Validation').attr('disabled', 'disabled');
            helperApp.SmartTextArea('Note', 512, 510);
            helperApp.RefreshDOM();
        })
    };
    /** */
    portalApp.submitSection8Contact = () => {
        $(`#js-form-section8contact`).submit((e) => {
            e.preventDefault();
            e.stopImmediatePropagation();

            if ($(`#js-form-section8contact`).valid()) {
                let ajaxOptions = {
                    success: (data) => {
                        helperApp.NotySuccess(data);
                        $(`#js-form-section8contact`).trigger('reset');
                        helperApp.DynamicModal('lg', 'hide', '', '');
                    },
                    error: (xhr, textStatus, errorThrown) => {
                        helperApp.NotyError("An error was logged, if problem persist contact Tech Support");
                    }
                };
                $(`#js-form-section8contact`).ajaxSubmit(ajaxOptions);
            } else {
                helperApp.NotyError("Some fields in your form are required, complete and then submit...");
            }
        });
    };
    /** */
    portalApp.initProcurement = () => {
        helperApp.SmartTextArea('Note', 2048, 2045);
    }
    /** */
    portalApp.submitProcurementLetterOfInterest = () => {
        $(`#js-form-procurementletterofinterest`).submit((e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            if ($(`#js-form-procurementletterofinterest`).valid()) {
                let ajaxOptions = {
                    beforeSend: () => {
                        helperApp.BlockUI('js-pnl-procurementletter');
                    },
                    success: (data) => {
                        helperApp.NotySuccess(data); $(`#js-form-procurementletterofinterest`).trigger('reset');
                        grecaptcha.reset();
                        helperApp.UnBlockUI('js-pnl-procurementletter');
                        $('#js-btn-submit').html('Complete the Captcha Validation').attr('disabled', 'disabled');
                        helperApp.SmartTextArea('Note', 2048, 2045);
                    },
                    error: (xhr, textStatus, errorThrown) => {
                        helperApp.NotyError("An error was logged, if problem persist contact Tech Support");
                    }
                };
                $(`#js-form-procurementletterofinterest`).ajaxSubmit(ajaxOptions);
            } else {
                helperApp.NotyError("Some fields in your form are required, complete and then submit...");
            }
        });
    };

    /**** Agency Portal */
    /**
     *
     * @param {any} id
     */
    portalApp.removeSubscription = (id) => {
        axios.get(`${urls.removeSubscription}${id}`).then((response) => {
            if (response.data.result === true) {
                helperApp.NotySuccess("Subscription was successfully removed from your records...");
                helperApp.RefreshUI();
            } else {
                helperApp.NotyError(response.data.message);
            }
        })
    };

    /** */
    portalApp.initSubscriptionSubmissionUi = () => {
        $('#btn-savecategory').attr('disabled', true).html('Select Category(s) before saving');
    }

    /**
     *
     * @param {any} e
     */
    portalApp.initSubscriptionSubmission = (e) => {
        let chks = $('input[type="checkbox"]');
        let chkOne = Array.prototype.slice.call(chks).some(x => x.checked);

        if (chkOne === true) {
            $('#btn-savecategory').removeAttr('disabled').attr('type', 'submit').html('Save your Selection');
        } else {
            $('#btn-savecategory').attr('disabled', true).attr('type', 'button').html('Select Category(s) before saving');
        }
    };

    return portalApp;
})({});